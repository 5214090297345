import { fetchAccounts } from "./actions"

const FETCH_ACCOUNTS = 'accounts/FETCH_ACCOUNTS'
const FETCH_ACCOUNTS_SUCCESS = 'accounts/FETCH_ACCOUNTS_SUCCESS'
const FETCH_ACCOUNTS_FAILURE = 'accounts/FETCH_ACCOUNTS_FAILURE'

const FETCH_ENS_NAME = 'accounts/FETCH_ENS_NAME'
const FETCH_ENS_NAME_SUCCESS = 'accounts/FETCH_ENS_SUCCESS'
const FETCH_ENS_NAME_FAILURE = 'accounts/FETCH_ENS_FAILURE'


export {
    FETCH_ACCOUNTS,
    FETCH_ACCOUNTS_SUCCESS,
    FETCH_ACCOUNTS_FAILURE,
    
    FETCH_ENS_NAME,
    FETCH_ENS_NAME_FAILURE,
    FETCH_ENS_NAME_SUCCESS,
}

